export const AccionesDatosSeguridad = {
    ACTUALIZAR_DATOS_SEGURIDAD: "SEGURIDAD/ACTUALIZAR_DATOS_SEGURIDAD",
    SAGA_LOGIN_REQUEST: 'SAGA_LOGIN_REQUEST',
    SAGA_LOGIN_REQUEST_COMPLETED: 'SAGA_LOGIN_REQUEST_COMPLETED',
    SAGA_LOGOUT_REQUEST: 'SAGA_LOGOUT_REQUEST',
    SAGA_LOGOUT_REQUEST_COMPLETED: 'SAGA_LOGOUT_REQUEST_COMPLETED',
    CAMBIAR_IDIOMA_USUARIO: "SEGURIDAD/CAMBIAR_IDIOMA_USUARIO",
    SAGA_UPDATE_PASS:"SAGA_UPDATE_PASS",
    SAGA_UPDATE_PASS_COMPLETED:"SAGA_UPDATE_PASS_COMPLETED",
    SEND_MAIL:"SEND_MAIL",
    SEND_MAIL_CONTACTO:"SEND_MAIL_CONTACTO",
    LOGIN_PAGE: "USUARIOS/LOGIN_PAGE"
};
