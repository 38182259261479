export const AccionesDatosUsuario = {
    ACTUALIZAR_DATOS_USUARIOS: "USUARIOS/ACTUALIZAR_DATOS_USUARIOS",
    BUSCAR_USUARIO: "USUARIOS/BUSCAR_USUARIO",
    BUSCAR_USUARIO_COMPLETED: "USUARIOS/BUSCAR_USUARIO_COMPLETED",
    NAVEGAR_A_USUARIOS: "USUARIOS/NAVEGAR_A_USUARIOS",
    CREAR_NUEVO_USUARIO: "USUARIOS/CREAR_NUEVO_USUARIO",
    GUARDAR_USUARIO: "USUARIOS/GUARDAR_USUARIO",
    OBTENER_DATOS_USUARIO: "USUARIOS/OBTENER_DATOS_USUARIO",
    OBTENER_DATOS_USUARIO_COMPLETED: "USUARIOS/OBTENER_DATOS_USUARIO_COMPLETED",
    ACTUALIZAR_DATOS_USUARIO_SELECCIONADO: "USUARIOS/ACTUALIZAR_DATOS_USUARIO_SELECCIONADO",
    USUARIO_ELIMINAR: "USUARIOS/USUARIO_ELIMINAR",
    USUARIO_ELIMINADO: "USUARIOS/USUARIO_ELIMINADO",
    ABOUT_PAGE: "USUARIOS/ABOUT_PAGE",
    COOKIES_PAGE: "USUARIOS/COOKIES_PAGE",
    LEGAL_PAGE: "USUARIOS/LEGAL_PAGE",
    CONTACTO_PAGE: "USUARIOS/CONTACTO_PAGE"
};
