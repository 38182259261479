export const AccionesDatosEnlaces = {
    ACTUALIZAR_DATOS_ENLACES: "ENLACES/ACTUALIZAR_DATOS_ENLACES",
    OBTENER_DATOS_ENLACE: "ENLACES/OBTENER_DATOS_ENLACE",
    OBTENER_DATOS_ENLACE_COMPLETED: "ENLACES/OBTENER_DATOS_ENLACE_COMPLETED",
    ACTUALIZAR_DATOS_ENLACE_SELECCIONADO: "ENLACES/ACTUALIZAR_DATOS_ENLACE_SELECCIONADO",
    DATOS_ELIMINAR: "ENLACES/OBTENER_DATOS_ELIMINAR",
    DATOS_ELIMINADOS: "ENLACES/OBTENER_DATOS_ELIMINADOS",
    CREAR_NUEVO_ENLACE: "ENLACES/CREAR_NUEVO_ENLACE",
    MAIN_PAGE: "ENLACES/MAIN_PAGE",
    GUARDAR_ENLACE: "ENLACES/GUARDAR_ENLACE",
    BUSCAR_ENLACES: "ENLACES/BUSCAR_ENLACES",
    BUSCAR_ENLACES_COMPLETED: "ENLACES/BUSCAR_ENLACES_COMPLETED",
    OBTENER_ULTIMOS_ENLACES: "ENLACES/OBTENER_ULTIMOS_ENLACES",
    OBTENER_ULTIMOS_ENLACES_COMPLETED: "ENLACES/OBTENER_ULTIMOS_ENLACES_COMPLETED",
    OBTENER_MAS_VOTADOS: "ENLACES/OBTENER_MAS_VOTADOS",
    OBTENER_MAS_VOTADOS_COMPLETED: "ENLACES/OBTENER_MAS_VOTADOS_COMPLETED",
    VOLVER_ATRAS: "ENLACES/VOLVER_ATRAS",
    LISTAS_PAGE: "ENLACES/LISTAS_PAGE",
    BUSCAR_ENLACES_USUARIO: "ENLACES/BUSCAR_ENLACES_USUARIO",
    BUSCAR_ENLACES_USUARIO_COMPLETED: "ENLACES/BUSCAR_ENLACES_USUARIO_COMPLETED",
    LIMPIAR_BUSQUEDA: "ENLACES/LIMPIAR_BUSQUEDA",
    ELIMINAR_ENLACE_DE_USUARIO: "ENLACES/ELIMINAR_ENLACE_DE_USUARIO",
    PUBLIC_PAGE: "PUBLIC_PAGE"
};
