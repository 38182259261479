import { Button, CssBaseline, Grid, Paper, TextField, Link, FormControlLabel, Checkbox } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { mapStateToProps } from 'configuracion/store/initial-state';
import { IEstadoAplicacion } from 'dominio/estado/estado-aplicacion';
import { IUsuario } from 'dominio/usuario';
import { getLoggedUserData } from 'infraestructura/auth/auth-manager';
import Loading from 'interfaz/admin/paginas/Loading';
import * as React from 'react';
import { connect } from 'react-redux';
import { loginRequest, loginPass, sendMail } from '../acciones/creators/login-saga.action.creator';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from "react-i18next";
import { CookieBanner } from "react-cookie-law-customizable";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import dataProvincias from './../../provincias.json'
import PiePaginaLogin from 'interfaz/admin/paginas/PiePaginaLogin';
/*import YouTube from 'react-youtube';*/

type LoginProps = IEstadoAplicacion & LoginDispatchProps;

function Alert(props: AlertProps) { return <MuiAlert elevation={6} variant="filled" {...props} />; }

interface LoginDispatchProps {

  doLogin(usuario: string, pwd: string): any;
  doUpdatePwd(usuarioUpdate: IUsuario): any;
  doSendMail(usuario: IUsuario): any;
}

const mapDispatchToProps = (dispatch: React.Dispatch<any>) => ({
  doLogin: (usuario: string, pwd: string) => {
    dispatch(loginRequest(usuario, pwd))
  },
  doUpdatePwd: (usuarioUpdate: IUsuario) => {
    dispatch(loginPass(usuarioUpdate))
  },
  doSendMail: (usuario: IUsuario) => {
    dispatch(sendMail(usuario))
  }
});


const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/images/girlNurse.jpeg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },


  typography: {
    fontFamily: '"Poppins"',
  },

  paper: {
    margin: theme.spacing(10, 4, 5, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: theme.spacing(3, 5),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0.5),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },

  copyright: {
    color: 'rgb(31, 117, 114)',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0)
  },

}));

export interface Provincia {
  id: string;
  nombre: string;
}

const _Login = (props: LoginProps) => {

  //Configuracion i18n
  const { t } = useTranslation();
  const loginModalPassNueva = t("translation:login.modal.passNueva");
  const altaEditarProvincia = t("translation:altaEditar.provincia");

  const [openCondiciones, setOpenCondiciones] = React.useState(false);

  const handleClickOpenCondicionesDialog = () => {
    setOpenCondiciones(true);
  };

  const handleCloseCondicionesDialog = () => {
    setOpenCondiciones(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    clearSendMailFormData();
    setOpen(false);
  };

  const [nombreMedico, setNombreMedico] = React.useState<String>('');
  const [apellidoMedico, setApellidoMedico] = React.useState<String>('');
  const [emailMedico, setEmailMedico] = React.useState<String>('');
  const [numeroColegiadoMedico, setNumeroColegiadoMedico] = React.useState<String>('');
  const [provincia, setProvincia] = React.useState<Provincia | null | undefined>(undefined);
  const [errorSendMail, setErrorSendMail] = React.useState<boolean>(false);
  const [condicionesAceptadas, setCondicionesAceptadas] = React.useState<boolean>(false);

  const handleCondicionesAceptadas = (e: any) => {
    setCondicionesAceptadas(!condicionesAceptadas);
  }
  const handleErrorSendMail = (e: any) => {
    setErrorSendMail(e);
  }
  const handleChangeNombreMedico = (e: any) => {
    setNombreMedico(e.target.value);
  }
  const handleChangeApellidoMedico = (e: any) => {
    setApellidoMedico(e.target.value);
  }
  const handleChangeEmailMedico = (e: any) => {
    setEmailMedico(e.target.value);
  }
  const handleChangeNumeroColegiadoMedico = (e: any) => {
    setNumeroColegiadoMedico(e.target.value);
  }

  const provincias: Provincia[] = dataProvincias;

  const handleChangeProvincia = (e: any) => {
    setProvincia(e);
  }



  const handleSendMail = (event): boolean => {

    const result = condicionesAceptadas && nombreMedico && apellidoMedico && numeroColegiadoMedico && emailMedico && provincia != undefined;
    if (result) {
      const usuario: IUsuario = Object.assign({}, {
        nombre: nombreMedico,
        apellidos: apellidoMedico,
        email: emailMedico,
        colegiado: numeroColegiadoMedico,
        provincia: provincia
      }) as IUsuario;
      props.doSendMail(usuario);
      handleCloseDialog();
      clearSendMailFormData();
    }

    handleErrorSendMail(!result);
    return result;
  }

  const clearSendMailFormData = () => {

    setCondicionesAceptadas(false);
    setErrorSendMail(false);
    setNombreMedico('');
    setApellidoMedico('');
    setEmailMedico('');
    setNumeroColegiadoMedico('');
    setProvincia(undefined);
  }

  const classes = useStyles();

  const [username, setUsername] = React.useState(
    ''
  );

  const [pass, setPass] = React.useState(
    ''
  );

  const [passNew, setPassNew] = React.useState(
    ''
  );

  const [passConfirm, setPassConfirm] = React.useState(
    ''
  );

  const handleChangePassword = (e: any) => {
    setPass(e.target.value);
  }

  const handleChangeNewPassword = (e: any) => {
    setPassNew(e.target.value);
  }

  const handleChangePasswordConfirm = (e: any) => {
    setPassConfirm(e.target.value);
  }

  const savePwd = () => {
    const usuarioUpdate: IUsuario = getLoggedUserData();
    usuarioUpdate.pwd = passNew;

    if (passNew !== '' && passConfirm !== '' && passNew === passConfirm) {
      props.doUpdatePwd(usuarioUpdate)
    } else {
      return;
    }
  }

  // validaciones

  const errorPassword = () => {
    return pass === ''
  }

  const errorPasswordConfirm = () => {
    return passConfirm === ''
  }


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);


  const handleLoginKeyDown = (event) => {
    if (event.key === 'Enter') {
      props.doLogin(username, pass)
      firstTime = false;
    }
  }

  const handleChangePassKeyDown = (event) => {
    if (event.key === 'Enter') {
      savePwd();
      firstTime = false;
    }
  }

  // actualizar contraseña
  const necesitaCambiarContrasena = () => { return getLoggedUserData().necesitaCambiarContrasena; }

  let firstTime = true;
  /* const opts = {
     height: '390',
     width: '640',
     playerVars: {
       // https://developers.google.com/youtube/player_parameters
       autoplay: 1,
     },
     
         <YouTube videoId="1EYHP4eSkWo"  />
   };*/


  return (
    <div className="CajaLogin">
      < div className="Login" >
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div
              className="MuiTypography-alignCenter footerLogin"
              style={{ paddingTop: '40px', marginBottom: '-40px', fontSize: '1.1rem' }}
            >
              <b>
                <span
                  style={{ fontSize: '1.2rem' }}
                >
                  TODOS LOS VIDEOS SON RECOMENDADOS POR MÉDICOS COLEGIADOS
                </span>
              </b>
            </div>
            <div className={classes.paper}>
              <img className="logoLogin" src="/images/logo-nuevo.png" alt="portada" />

              <form className={classes.form} noValidate>

                {!firstTime && !props.datosSeguridad.access_token ?
                  <Loading />
                  :
                  null
                }


                <Snackbar
                  open={errorSendMail}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  autoHideDuration={5000}
                  onClose={handleClose}
                  className="modalError"
                >
                  <Alert severity="error">Introduce todos los datos requeridos y acepta las condiciones</Alert>
                </Snackbar>

                <Snackbar
                  open={!props.datosSeguridad.access_token && props.datosSeguridad.error.length > 0
                    && props.datosEstadoPromesa.promesaFinalizada && props.datosEstadoPromesa.finalizadoCorrecto}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  autoHideDuration={10000}
                  onClose={handleClose}
                  className="modalError"
                >
                  <Alert severity="error">Tu usuario o contraseña no son correctos</Alert>
                </Snackbar>

                <Snackbar
                  open={props.datosEstadoPromesa.promesaFinalizada && !props.datosEstadoPromesa.finalizadoCorrecto}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  autoHideDuration={5000}
                  onClose={handleClose}
                  className="modalError"
                >
                  <Alert severity="error">Ha ocurrido un error en el servidor. Contacte con su proveedor.</Alert>
                </Snackbar>

                <Snackbar
                  open={passNew !== passConfirm}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  autoHideDuration={5000}
                  onClose={handleClose}
                  className="modalError"
                >
                  <Alert severity="error">Debe introducir la misma contraseña en los 2 campos</Alert>
                </Snackbar>


                <div hidden={necesitaCambiarContrasena()}>

                  <div className="inputLogin">
                    <TextField
                      variant="filled"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Usuario / Erabiltzailea"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      value={username}
                      onKeyDown={event => handleLoginKeyDown(event)}
                      onChange={event => setUsername(event.target.value)}
                    />
                    <TextField
                      variant="filled"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Contraseña / Pasahitza"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      autoComplete="current-password"
                      value={pass}
                      onKeyDown={event => handleLoginKeyDown(event)}
                      onChange={handleChangePassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>

                  {/*<div className="checkboxLogin">
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Recuérdame / Gogorarazi"
                    />
                    </div>*/}

                  <div className="botonEntrar">
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={
                        (e) => {
                          e.preventDefault();
                          props.doLogin(username, pass)
                          firstTime = false;
                        }
                      }
                    >
                      ENTRAR / SARTU
                    </Button>
                  </div>

                </div>


                <div className="actualizarPass" hidden={!necesitaCambiarContrasena()}>
                  <span>{loginModalPassNueva}</span>
                </div>

                <div hidden={!necesitaCambiarContrasena()}>

                  <div className="inputLogin">
                    <TextField
                      variant="filled"
                      margin="normal"
                      fullWidth
                      id="emailCambiarPass"
                      label="Contraseña Nueva / Pasahitza Berria"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      error={errorPassword()}
                      type={showPassword ? "text" : "password"}
                      value={passNew}
                      onChange={handleChangeNewPassword}
                      required
                      onKeyDown={event => handleChangePassKeyDown(event)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <TextField
                      variant="filled"
                      margin="normal"
                      fullWidth
                      name="password"
                      label="Confirmar Contraseña/ Baieztatu Pasahitza"
                      error={errorPasswordConfirm()}
                      type={showPassword ? "text" : "password"}
                      id="passwordCambiarPass"
                      autoComplete="current-password"
                      value={passConfirm}
                      onChange={handleChangePasswordConfirm}
                      required
                      onKeyDown={event => handleChangePassKeyDown(event)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>

                  <div className="botonEntrar2">
                    <div className="botonEntrar">
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={
                          (e) => {
                            e.preventDefault();
                            savePwd();
                            firstTime = false;
                          }
                        }
                      >
                        ENTRAR / SARTU
                      </Button>
                    </div>
                  </div>

                </div>
              </form>
            </div>

            <div className="MuiTypography-alignCenter footerLogin">
              <span>Si eres un médico y quieres formar parte de Trailumed, </span>
              <Link
                style={{ fontSize: '1.1rem' }}
                id="joinLink"
                className="footerLogin"
                onClick={handleClickOpenDialog}
                underline="hover"
                component="button"
                variant="body2"
              >
                haz click aquí
              </Link>
            </div>


          </Grid>
        </Grid>

      </div >

      <PiePaginaLogin />
      <CookieBanner
        styles={{
          dialog: {
            position: 'absolute',
            bottom: '0',
            zIndex: '100000',
            backgroundColor: '#f8f7f7',
            padding: '10px',
            width: '100%'
          },
          selectPane: {
            display: 'none'
          },
        }}
        message="Este portal web únicamente utiliza cookies propias con finalidad técnica, no recaba ni cede datos de carácter personal de los usuarios sin su conocimiento. Sin embargo, contiene enlaces a sitios web de terceros con políticas de privacidad ajenas a esta web, que usted podrá decidir si acepta o no cuando acceda a ellos."
        wholeDomain={true}
        acceptButtonText='Acepto'
        privacyPolicyLinkText='Más información'
        policyLink='../PoliticaCookies'
        onAccept={() => { }}
        onAcceptPreferences={() => { }}
        onAcceptStatistics={() => { }}
        onAcceptMarketing={() => { }}
        showPreferencesOption={false}
        showStatisticsOption={false}
        showMarketingOption={false}
      />

      <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Únete a TRAILUmed</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para entrar a formar parte de TRAILUmed, rellena el siguiente formulario:
          </DialogContentText>
          <div className="inputLogin">
            <TextField
              id="nombreDialog"
              label="Nombre"
              type="text"
              variant="filled"
              margin="normal"
              fullWidth
              name="nombreDialog"
              autoFocus
              value={nombreMedico}
              onChange={handleChangeNombreMedico}
            />
            <TextField
              id="apellidosDialog"
              label="Apellidos"
              type="text"
              variant="filled"
              margin="normal"
              fullWidth
              name="apellidosDialog"
              autoFocus
              value={apellidoMedico}
              onChange={handleChangeApellidoMedico}
            />
            <TextField
              id="numColegiadoDialog"
              label="Nº colegiado"
              type="text"
              variant="filled"
              margin="normal"
              fullWidth
              name="numColegiadoDialog"
              autoFocus
              value={numeroColegiadoMedico}
              onChange={handleChangeNumeroColegiadoMedico}
            />
            <TextField
              id="emailDialog"
              label="Email"
              variant="filled"
              margin="normal"
              fullWidth
              name="numColegiadoDialog"
              autoFocus
              type="email"
              value={emailMedico}
              onChange={handleChangeEmailMedico}
            />
          </div>

          <div className="categoriasForm">
            <Autocomplete
              id="combo-option-provincia"
              value={provincia}
              options={(provincias.sort((a, b) => a.nombre.localeCompare(b.nombre)))}
              getOptionLabel={provincia => (provincia.nombre)}
              onChange={(event: any, value: any) => {
                handleChangeProvincia(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={altaEditarProvincia}
                  variant="filled"
                  fullWidth
                  margin="normal"
                />
              )}
            />
          </div>

          <div className="checkboxLogin">
            <FormControlLabel
              control={<Checkbox value={condicionesAceptadas} color="primary" onChange={handleCondicionesAceptadas} />}
              label="He leído y acepto"
            />
            <Link
              style={{ fontSize: '1.1rem' }}
              id="joinLink"
              className="footerLogin"
              onClick={handleClickOpenCondicionesDialog}
              underline="hover"
              component="button"
              variant="body2"
            >
              Leer condiciones
            </Link>
          </div>
        </DialogContent>
        <DialogActions>

          <div className="botonEntrar2">
            <div className="botonEntrar">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleCloseDialog}
              >
                Cerrar
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSendMail}
              >
                Unirse
              </Button>
            </div >
          </div >
        </DialogActions>
      </Dialog>


      <Dialog open={openCondiciones} onClose={handleCloseCondicionesDialog} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="lg">
        <DialogTitle id="form-dialog-title">CONDICIONES DE ADHESIÓN A LA PLATAFORMA TRAILUMED 2020 SL PARA PROFESIONALES MÉDICOS</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p><b>
              1. TRAILUMED 2020 SL Y LOS SERVICIOS PRESTADOS AL PROFESIONAL MÉDICO
</b></p>
            <br />
            <p>
              TRAILUMED 2020 SL, provista de NIF B02846954, con domicilio en Getxo, Calle Udaberria,
              14, CP 48992, pone a disposición de los profesionales MÉDICOS, una plataforma digital
              accesible en Internet mediante la dirección web www.trailumed.com y los dominios derivados a
              ésta de: Trailu.net, Trailu.org, Trailu.es, Trailumed.com, Trailumed.online y Trailumed.net, así
              como una app disponible para IPhone y Android, que posibilita a los profesionales de la
              medicina que suscriban las presentes condiciones establecidas por TRAILUMED 2020 SL,
              ofrecer un servicio de información añadido a través de la recomendación a sus clientes de
              enlaces en internet. Asimismo, y como valor añadido, posibilita la visibilidad de los
              profesionales por otras personas que no sean clientes de sus consultas.
              Los profesionales adheridos a TRAILUMED 2020 SL se sujetarán a las presentes condiciones,
              que se entienden aceptadas por los mismos, y que constituyen el documento o contrato que
              regulará la relación jurídica y contractual con TRAILUMED 2020 SL.
              Los médicos adheridos a este contrato disfrutarán de las ventajas que la plataforma ofrece,
              como son:
              - Posibilidad de llegar a prescriptores y pacientes mediante el uso de la herramienta.
              - Publicitarse como profesional, siempre que sea a través de la puesta a disposición de
              las y los usuarios de información médica de valor.
              - Gestionar conocimiento mediante publicaciones, artículos y difusiones análogas
              - Realizar propuestas de cualquier índole a TRAILUMED 2020 SL, vinculadas con el
              objeto de la plataforma.
              TRAILUMED 2020 SL es consciente de la normativa de protección de datos y, por ello, tiene
              implementada una política de protección de datos que incluye el nombramiento del Delegado
              de Protección de Datos, la realización de auditorías periódicas, implementación de medidas de
              seguridad, entre otras y acredita que su plataforma tecnológica cumple con los preceptos
              exigidos por la vigente normativa y que el sistema incorpora las medidas de seguridad
              establecidas conforme a la vigente normativa de protección de datos personales. Igualmente,
              TRAILUMED 2020 SL posibilita el ejercicio de derechos de acceso, rectificación, supresión,
              oposición, limitación y portabilidad a los titulares de los datos o sus representantes legales, los
              cuales pueden solicitarlos mediante escrito dirigido a la entidad.
              TRAILUMED 2020 SL es Responsable de Tratamiento de los datos personales recabados y
              tratados en su plataforma por la propia TRAILUMED 2020 SL. El profesional médico adherido
              es Responsable de Tratamiento de aquella información que obtenga directamente con motivo
              de su ejercicio profesional y a la que no acceda y/o trate TRAILUMED 2020 SL.
              El profesional médico como Responsable de Tratamiento, no realizará en TRAILUmed ningún
              otro tratamiento que no sea única y exclusivamente el que se derive de la relación médico-
              paciente y para prestar los servicios ofrecidos a través de la Plataforma.
              El Responsable de Tratamiento en cada caso implementará las medidas de seguridad y demás
              obligaciones legales establecidas por la vigente legislación en materia de protección de datos
              en la parte que le corresponda, garantizando la integridad, seguridad y confidencialidad de la
              información personal objeto de tratamiento.
</p>
            <br />
            <p><b>
              2. OBLIGACIONES ACEPTADAS POR EL PROFESIONAL
</b></p>
            <br />
            <p>
              El profesional médico es conocedor y consiente expresamente la utilización de sus datos
              personales, esencialmente identificativos y curriculares, y en caso de él mismo haberlas
              expresamente cedido, también su imagen y voz, para que sea divulgada en los canales de
              comunicación y puesta a disposición de los usuarios de la Plataforma como elemento
              identificador y como dato personal.
              Igualmente, el profesional adherido se obliga a informar a TRAILUMED 2020 SL de cualquier
              infracción de los derechos de propiedad industrial o intelectual o de cualquier otra índole, así
              como de todas aquellas circunstancias que contribuyan a un mejor servicio de la plataforma y
              de percepción de los consumidores y demás profesionales vinculados a la plataforma, bajo el
              principio de buena fe contractual.
              El profesional médico que voluntariamente desee beneficiarse de los servicios ofrecidos en la
              relación establecida mediante este contrato, deberá manifestar de forma expresa que se
              encuentra debidamente acreditado y en posesión del correspondiente título oficial que habilite
              expresamente para el ejercicio de la medicina y que está al corriente de todas y cada una de
              las obligaciones exigibles por ley para realizar su actividad profesional dentro de la legalidad en
              su ámbito territorial donde la desarrolle, incluido el cumplimiento de la legislación en materia de
              protección de datos personales, la legislación sanitaria y el código deontológico que pudiera
              serle exigible.
              Cualquier irregularidad o vulneración de la legalidad en la que pueda incurrir el médico que
              suscribe este contrato habilitará a TRAILUMED 2020 SL para que pueda emprender cuantas
              acciones legales ajustadas a Derecho considere convenientes en defensa de sus legítimos
              derechos.
              De forma específica se señala que TRAILUMED 2020 SL se personará como acusación
              particular contra el profesional adherido en aquellos casos de falsedad de datos, suplantación
              de personalidad, intrusismo profesional o revelación del deber de secreto de la información
              confidencial o personal a la que pueda acceder o tratar como profesional médico derivada de la
              vinculación establecida en esta relación con TRAILUMED 2020 SL.
              El profesional responderá de la falta de veracidad de los datos introducidos.
              Así mismo, el profesional exonera de cualquiera responsabilidad a TRAILUMED 2020 SL y su
              personal, administradores, socios, o cualquier parte vinculada a la misma, por cualquier
              reclamación por parte de usuarios o terceros derivada de los servicios prestados por el
              profesional, relativos a la medicina y demás servicios objeto de la Plataforma (consejos,
              tratamientos, recomendaciones, etc. facilitados o suministrados por el profesional).
</p>
            <br />
            <p><b>
              3. CONDICIONES GENERALES PARA LAS PARTES
</b></p>
            <br />
            <p>
              El profesional, así como TRAILUMED 2020 SL, manifiestan y reconocen estar al corriente de
              todas las obligaciones legales (tributarias, laborales, etc.) pertinentes para el ejercicio de la
              actividad profesional exigible en la presente relación establecida, incluyendo el título
              profesional que exige la ley y que habilita para el desarrollo de dicha actividad. En este sentido,
              el profesional se obliga a mantener en vigor, durante la relación contractual con TRAILUMED
              2020 SL, una póliza de responsabilidad civil que cubra los posibles daños causados a usuarios
              o terceros en virtud de su actividad desarrollada en la plataforma TRAILUMED 2020 SL,
              actividad de la cual es solamente responsable el profesional, y en todo caso cualesquiera
              pólizas de seguro que exija la normativa vigente aplicable en cada caso.
              La relación jurídica establecida con la aceptación del presente contrato de adhesión por parte
              del profesional médico no implica la creación de ningún tipo de relación laboral con
              TRAILUMED 2020 SL.
              Ambas partes se reconocen recíproca experiencia en procesos de asistencia y relación con
              usuarios y consumidores, existiendo un know-how propio de cada una de ellas, que puede

              significar un valor diferencial digno de protección, al cual la otra parte pueda tener acceso y
              sobre el que se establece un compromiso de no revelar a terceros.
</p>
            <br />
            <p><b>
              4. RESOLUCIÓN DE LA VINCULACIÓN
</b></p>
            <br />
            <p>
              Cualquiera de las partes puede desvincularse, en cualquier momento, de la relación aquí
              establecida por medio de cualquier medio efectivo de comunicación que acredite su recepción,
              sin que de tal finalización se origine ningún derecho a indemnización alguna para ninguna de
              las partes.
              TRAILUMED 2020 SL SE RESERVA EL DERECHO A DAR DE BAJA DE FORMA
              UNILATERAL ENLACES CONCRETOS CUANDO CONSIDERE QUE NO CUMPLEN CON EL
              CÓDIGO DEONTOLÓGICO DE LA PROFESIÓN MÉDICA O CUANDO SU FIN PRIMORDIAL
              SEA EXCLUSIVAMENTE COMERCIAL.
              Cualquier proceso o servicio en curso deberá ser finalizado por aquella parte que tenga la
              obligación de prestarlo.
</p>
            <br />
            <p><b>
              5. SOLUCIÓN DE CONTROVERSIAS
</b></p>
            <br />
            <p>
              Las partes acuerdan solucionar los conflictos que pudieran surgir de la presente relación, de
              forma amistosa por medio del diálogo, buena fe y la buena voluntad. En caso de que eso no
              fuera posible, las partes acuerdan someterse a un procedimiento de arbitraje, en la entidad que
              escoja la parte que lo invoque, aceptando el resultado del mismo y renunciando a cualquier
              fuero que pudiera serle de aplicación, sin perjuicio de recurrir a la vía judicial, si el proceso de
              arbitraje no fuera ajustado a derecho.
</p>
            <br />
            <p><b>
              6. ACEPTACIÓN
</b></p>
            <br />
            <p>
              El profesional médico, al clicar “acepto” se compromete a cumplir con todo lo dispuesto en el
              presente contrato de adhesión, al cumplimiento de lo preceptuado en el Aviso Legal obrante en
              la Plataforma de TRAILUMED 2020 SL y consiente expresamente al tratamiento de sus datos
              personales para las finalidades propias de la relación establecida y de lo señalado en este
              documento.
</p>
            <br />
            <p>
              INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS: En cumplimiento de la normativa
              legal vigente en materia de protección de datos personales, se le informa que El Responsable
              del Tratamiento de sus datos personales es TRAILUMED 2020, S.L. La Finalidad del
              tratamiento es materializar la relación contractual y la Legitimación es el contrato, o el
              consentimiento, cuando proceda. No se cederán los datos a terceros ni serán usados para
              otras finalidades, salvo los legalmente previstos o los necesarios para materializar la relación
              contractual. Puede ejercer sus Derechos a acceder, rectificar, oponerse, limitar, portar y
              suprimir los datos, escribiendo al titular del sitio. Ud. se responsabiliza de que los enlaces a
              otras páginas web que Ud. dé de alta en TRAILUmed no están protegidos por propiedad
              intelectual. Información adicional, visitando: http://www.trailu.es/AcercaDe. Puede contactar en
              cualquier momento con el Delegado de Protección de datos en dpd@trailumed.com, o escribir
              a Trailumed 2020, C/. Udaberria, 14, 48992. Getxo (Bizkaia)
              Las mismas obligaciones anteriores se aplican a la firma de este documento.
</p>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="botonEntrar2">
            <div className="botonEntrar">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleCloseCondicionesDialog}
              >
                OK
              </Button>
            </div >
          </div >
        </DialogActions>
      </Dialog>

    </div >
  );
}

export const Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(_Login);

export default Login;
