import * as React from "react";
import Lottie from 'react-lottie';
import * as heartbeatLoading from './heartbeat-loading.json'


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: heartbeatLoading,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    },
    speed: 2
};

const Loading = () => {


    return (
            <Lottie
                options={defaultOptions}
                source={require('./heartbeat-loading.json')}
                height={400}
                width={400}
            />
    )
}

export default Loading;
