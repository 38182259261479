export const AccionesDatosListas = {
  OBTENER_DATOS_LISTA: "LISTAS/OBTENER_DATOS_LISTA",
  OBTENER_DATOS_LISTA_COMPLETED: "LISTAS/OBTENER_DATOS_LISTA_COMPLETED",
  ACTUALIZAR_DATOS_LISTAS: "LISTAS/ACTUALIZAR_DATOS_LISTAS",
  ACTUALIZAR_DATOS_LISTA_SELECCIONADO:
    "LISTAS/ACTUALIZAR_DATOS_LISTA_SELECCIONADO",
  BUSCAR_LISTAS: "LISTAS/BUSCAR_LISTAS",
  BUSCAR_LISTAS_COMPLETED: "LISTAS/BUSCAR_LISTAS_COMPLETED",
  BUSCAR_MIS_LISTAS: "LISTAS/BUSCAR_MIS_LISTAS",
  BUSCAR_MIS_LISTAS_COMPLETED: "LISTAS/BUSCAR_MIS_LISTAS_COMPLETED",
  BUSCAR_MIS_LISTAS_POPUP: "LISTAS/BUSCAR_MIS_LISTAS_POPUP",
  BUSCAR_MIS_LISTAS_POPUP_COMPLETED: "LISTAS/BUSCAR_MIS_LISTAS_POPUP_COMPLETED",
  DATOS_ELIMINAR: "LISTAS/OBTENER_DATOS_ELIMINAR",
  DATOS_ELIMINADOS: "LISTAS/OBTENER_DATOS_ELIMINADOS",
  CREAR_NUEVA_LISTA: "LISTAS/CREAR_NUEVA_LISTA",
  GUARDAR_LISTA: "LISTAS/GUARDAR_LISTA",
  GUARDAR_LISTAS_COMPLETED: "LISTAS/GUARDAR_LISTAS_COMPLETED",
  BUSCAR_LISTA_USUARIO: "LISTAS/BUSCAR_LISTA_USUARIO",
  BUSCAR_LISTA_USUARIO_COMPLETED: "LISTAS/BUSCAR_LISTA_USUARIO_COMPLETED",
  CLONAR_LISTA: "LISTAS/CLONAR_LISTA",
  BUSCAR_LISTAS_USUARIOSPAGE: "LISTAS/BUSCAR_LISTAS_USUARIOSPAGE",
  BUSCAR_LISTAS_USUARIOSPAGE_COMPLETED: "LISTAS/BUSCAR_LISTAS_USUARIOSPAGE_COMPLETED",
  LIMPIAR_BUSQUEDA: "LISTAS/LIMPIAR_BUSQUEDA",
  ELIMINAR_LISTA_DE_USUARIO: "USUARIOS/ELIMINAR_LISTA_DE_USUARIO"
};
